@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600&display=swap");
$gris_claro: #f6f6f6;
$main_color: #635cff;
$dark_color: #2a1651;

/* Scrollbar */
::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 6px;
}
::-webkit-scrollbar-thumb:hover {
    background: #aaa;
}
.landingContainer * {
    font-family: "Inter";
}
html {
    overflow-x: hidden;
    scroll-behavior: smooth;
    object-fit: cover;
    background-size: cover;
    background-position-y: -1000px;
    background-color: rgba(255, 255, 255, 1);
    background-blend-mode: lighten;
}
.landingPage * {
    font-family: "Inter";
}
.landingContainer {
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
    max-width: 1120px;
    margin: auto;
    padding-top: 80px;
    // max-height: 680px;
    // overflow: hidden;
}
.privacyContainer {
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
    max-width: 1120px;
    margin: auto;
    padding-top: 10px;
    // overflow: hidden;
}
.landingLogo {
    transition: 0.2s;
    font-size: 28px;
    font-family: "Montserrat";
    color: #1a2641;
    margin-top: 13px;
    display: inline-block;
    font-weight: 700;
    letter-spacing: -1px;
    transform: scaleX(0.9);
    text-decoration: none;
    transform-origin: left;
    vertical-align: middle;
    margin-left: -1px;
}
.ingresarButton {
    transition: 0.2s;
    color: $main_color;
    margin-top: 14px;
    margin-right: 20px;
    padding: 8px 19px;
    padding-top: 7px;
    border: none;
    outline: none !important;
    cursor: pointer;
    font-size: 15px;
    font-weight: normal;
    float: right;
    background: transparent;
    border-radius: 50px;
    border: 1px solid transparent;
    &:hover {
        border: 1px solid rgba(0, 0, 0, 0.1);
        color: $dark_color;
    }
}
.registrarseButton {
    transition: 0.3s;
    color: white;
    background: white;
    margin-top: 14px;
    padding: 9px 20px;
    padding-top: 8px;
    border: none;
    outline: none !important;
    cursor: pointer;
    font-size: 15px;
    font-weight: 400;
    float: right;
    background: $main_color;
    border-radius: 50px;
    opacity: 1;
    &:hover {
        opacity: 1;
        background-color: $dark_color;
    }
}
.qrSection {
    height: fit-content;
    padding-bottom: 160px;
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
    max-width: 1120px;
    margin: auto;
}
.qrCode {
    width: 200px;
    opacity: 0.9;
    margin-top: -10px;
    margin-left: -18px;
    margin-bottom: 90px;
}
.heroTitle {
    margin: auto;
    margin-top: 8vh;
    font-size: 64px;
    font-weight: 600;
    max-width: 190%;
    letter-spacing: -3px;
    color: $dark_color;
    line-height: 1.1;
    transform-origin: left;
    text-align: center;
    width: 70%;
}
.sectionTheme {
    margin-top: 12%;
    color: $main_color;
    font-size: 14px;
    font-weight: 800;
    margin-bottom: -16px;
}
.sectionTitle {
    font-size: 32px;
    font-weight: 600;
    max-width: 160%;
    letter-spacing: -1px;
    color: #192439;
    line-height: 1.2;
    transform-origin: left;
}
.halfColumn {
    width: 100%;
    display: inline-block;
    vertical-align: top;
}
.landingHeaderTop {
    transition: 0.4s;
    padding-top: 20px;
    height: 64px;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0);
    backdrop-filter: blur(5px);
    box-shadow: none;
    border-color: rgba(0, 0, 0, 0.06);
}
.landingHeader {
    transition: 0.4s;
    height: 64px;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(5px) saturate(2);
    // box-shadow: 0 0 1.875rem 0 rgba(26, 46, 68, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    z-index: 99999;
}
.headerDiv {
    transition: 0.4s;
    width: 90%;
    max-width: 1120px;
    margin: auto;
}
.heroButtonContainer {
    width: 200px;
    height: 50px;
    display: block;
    margin: auto;
}
.heroButton {
    transition: 0.3s;
    display: block;
    margin: auto;
    margin-top: 36px;
    font-weight: 400;
    font-size: 18px;
    padding: 14px 30px;
    background-color: $main_color;
    cursor: pointer;
    color: white;
    border: none;
    border-radius: 60px;
    position: relative;
    overflow: hidden;
    &:hover {
        opacity: 0.9;
        span {
            opacity: 0.1;
        }
    }
    span {
        position: absolute;
        height: 60px;
        width: 60px;
        border-radius: 60px;
        background-color: white;
        top: 1px;
        left: 20px;
        display: block;
        opacity: 0;
        transition: opacity 0.3s;
    }
    svg {
        height: 22px;
        vertical-align: bottom;
        stroke: white;
        margin-left: 6px;
        margin-right: -10px;
    }
}
.heroText {
    margin: auto;
    margin-top: 30px;
    color: rgba(0, 0, 60, 0.6);
    font-weight: 400;
    font-size: 18px;
    width: 90%;
    max-width: 700px;
    text-align: center;
}
#figureMobile {
    display: none;
}
@media (max-width: 800px) {
    .heroTitle {
        font-size: 38px;
        letter-spacing: -2px;
        width: 85%;
        margin-top: 80px;
    }
    .halfColumn {
        width: 100%;
    }
    .registrarseButton {
        display: none;
    }
    .ingresarButton {
        margin-right: 0px;
    }
    .landingHeaderTop {
        transition: 0.4s;
        padding-top: 20px;
        height: 64px;
        width: 100vw;
        position: fixed;
        left: 0;
        top: 0;
        background-color: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(3px);
        box-shadow: none;
    }
}
.privacyArticle {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    padding-top: 60px;
    margin-top: 20px;
    padding-bottom: 80px;
    color: rgba(0, 0, 0, 0.6);
    h4 {
        padding-top: 20px;
        color: black;
    }
}
.landingHeader,
.landingHeaderTop {
    .logo {
        margin: auto;
        width: 26px;
        margin-bottom: 40px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 20px;
        margin-top: 18px;
    }
    .logo div {
        background-color: $main_color;
        height: 6px;
        width: 100%;
        margin-bottom: 5px;
        opacity: 0.9;
    }
    .middle {
        width: 80% !important;
    }
}
.boardDemo {
    width: calc(100% - 30px);
    margin: auto;
    box-shadow: 0 20px 100px -20px rgba(40, 40, 100, 0.3);
    border-radius: 20px;
    padding: 15px;
    margin-top: 14vh;
    height: fit-content;
    overflow: hidden;
    // border: 1px solid rgba(0,0,0,0.07);
    transition: all 200ms cubic-bezier(0.3, 0.98, 0.52, 0.1) 0s;
    animation: fadeInFigure 2s;
    img {
        border-radius: 14px;
    }
    @keyframes fadeInFigure {
        0% {
            opacity: 0;
            transform: translateY(100px);
        }
        20% {
            opacity: 0;
            transform: translateY(100px);
        }
        100% {
            opacity: 1;
        }
    }
}
.heroFigure {
    width: calc(100% - 0px);
    margin-top: 0%;
    margin-left: 0%;
    transition: all 200ms cubic-bezier(0.3, 0.98, 0.52, 0.1) 0s;
    z-index: -10;
    margin: auto;
    display: block;
    display: inline-block;
    z-index: 0;
}
.detailsSection {
    width: 100%;
    // background-color: rgba(90,50,90,0.05);
    padding-top: 10px;
    margin-top: 0px;
    u {
        position: relative;
        text-decoration: none;
        display: inline-block;
        span {
            height: 5px;
            width: 100%;
            display: block;
            position: absolute;
            background-color: $main_color;
            left: 0px;
            top: 42px;
            z-index: -1;
            opacity: 0.6;
        }
    }
}
.phone {
    width: 244px;
    height: 503px;
    border-radius: 36px;
    box-shadow: 0 50px 100px -20px rgba(40, 40, 100, 0.4), inset 0 -2px 6px 0 rgba(0, 0, 0, 0.4);
    background-color: #f6f9fc;
    padding: 0px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 2px;
    z-index: 99;
    transition: 0.2s;
    position: absolute;
    top: -40px;
    left: 87px;
    pointer-events: none;
}
.phoneScreen {
    transition: 0.3s;
    width: 220px;
    height: 480px;
    border-radius: 26px;
    background-color: white;
    margin: auto;
    margin-top: 10px;
    border: 1px solid rgba(0, 0, 0, 0.02);
    background-image: url(https://i.ibb.co/Ny3B6BF/image.webp);
    background-size: 480px;
    background-repeat: no-repeat;
    background-position-x: -125px;
    background-position-y: -120px;
    position: relative;
    overflow: hidden;
}
.screenTop {
    background-color: rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    height: 60px;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
}
.screenBottom {
    background-color: rgba(0, 0, 0, 0.2);
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    height: 120px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;
}
.cameraButton {
    bottom: 34px;
    left: 84px;
    height: 50px;
    width: 50px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 100px;
    position: absolute;
    z-index: 2;
    border: 1px solid rgba(255, 255, 255, 0.3);
    cursor: pointer;
    &:hover {
        background-color: rgba(255, 255, 255, 0.4);
    }
}
#phoneContainer {
    max-width: 1000px;
    // background-color: #f4f5f7;
    padding-bottom: 70px;
    border-radius: 16px;
    padding: 0px;
    padding: 60px 60px;
    width: calc(90% - 120px) !important;
    border-bottom-right-radius: 16px;
    border-top-right-radius: 16px;
    position: relative;
}
#refundsContainer {
    max-width: 1120px;
    // background-color: #f4f5f7;
    border-radius: 16px;
    padding: 0px;
    width: calc(90%) !important;
    border-bottom-right-radius: 16px;
    border-top-right-radius: 16px;
    position: relative;
    margin-top: 16vh;
}
#phoneController {
    position: absolute;
    height: 420px;
    width: 420px;
    border-radius: 240px;
    top: 40px;
    left: 0px;
    background-color: rgba(50, 50, 50, 0.04);
    z-index: 0;
    border: 2px solid rgba(0, 0, 0, 0);
    transition: 0.6s;
}
.detailsIllustration {
    position: absolute;
    height: 420px;
    width: 420px;
    border-radius: 240px;
    top: 40px;
    right: 80px;
    background-color: rgba(50, 50, 50, 0.04);
    z-index: 0;
    border: 2px solid rgba(0, 0, 0, 0);
    transition: 0.6s;
}
.detailsSectionRightContainer {
    display: inline-block;
    width: calc(100% - 300px);
    height: 360px;
    vertical-align: middle;
    z-index: 0;
    margin-left: 360px;
    margin-top: -20px;
    h1 {
        text-align: left;
        margin-left: 80px;
    }
}
.detailsSectionLeftContainer {
    display: inline-block;
    width: calc(100% - 300px);
    height: 360px;
    vertical-align: middle;
    z-index: 0;
    margin-left: 0px;
    margin-top: -20px;
    h1 {
        text-align: left;
        margin-left: 80px;
    }
    .subTitle {
        margin-left: 0px !important;
        margin-top: 120px !important;
        font-size: 46px;
        font-weight: 600;
        max-width: 190%;
        letter-spacing: -1px;
        color: $dark_color;
        line-height: 1.2;
        transform-origin: left;
        text-align: left;
        width: 70%;
    }
    .subHeadline {
        text-align: left;
        margin-left: 0px;
        margin-top: -10px;
        color: rgba(0, 0, 60, 0.6);
        font-size: 20px;
        max-width: 500px;
    }
}
.refundsImage {
    height: 410px;
    opacity: 1;
    margin-top: -30px;
}
.subHeadline {
    text-align: left;
    margin-left: 80px;
    margin-top: -10px;
    color: rgba(0, 0, 60, 0.6);
    font-size: 20px;
    max-width: 500px;
}
.landingPage {
    overflow: hidden;
    .subTitle {
        margin-left: 80px;
        margin-top: 10vh;
        font-size: 40px;
        font-weight: 700;
        max-width: 190%;
        letter-spacing: -1px;
        color: $dark_color;
        line-height: 1.2;
        transform-origin: left;
        text-align: left;
        width: 70%;
    }
}
.guideLines {
    position: fixed;
    top: 0px;
    left: 50%;
    width: 90%;
    height: 100vh;
    max-width: 1119px;
    z-index: 9999999999;
    background-color: transparent;
    transform: translateX(-50%);
    // border: 1px dashed rgba(0, 0, 0, 0.07);
    border-top: none;
    border-bottom: none;
    pointer-events: none;
    display: flex;
    div {
        width: 25%;
        height: 100vh;
        // border-right: 1px dashed rgba(0, 0, 0, 0.07);
    }
}
.bigHeadline {
    transition: all .4s;
    font-size: 50px;
    font-weight: 600;
    letter-spacing: -2px;
    margin-left: 20px;
    text-align: left;
    background-clip: text;
    -webkit-background-clip: text;
    background-attachment: fixed;
    color: white;
    width: 60%;
    margin-left: 120px;
    margin-top: 20px;
    // text-shadow: #635cffaa 1px 0 20px;
}
.freePlanHeader {
    position: absolute;
    bottom: 0px;
    left: 120px;
    background-color: white;
    width: 260px;
    max-width: 25%;
    height: 150px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.freePlanDiv {
    transition: all .4s;
    position: absolute;
    bottom: -360px;
    left: 0px;
    background-color: #f0f0f0;
    width: 100%;
    padding-top: 20px;
    height: 340px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}
.standardPlanHeader {
    position: absolute;
    bottom: 0px;
    left: calc(50% - 130px);
    background-color: #635cff;
    width: 260px;
    max-width: 25%;
    height: 150px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    box-shadow: 2px -96px 138px -108px rgba(46, 0, 20, 0.7) inset;
    // -webkit-box-shadow: 2px -96px 138px -108px rgba(66, 0, 50, 1) inset;
    // -moz-box-shadow: 2px -96px 138px -108px rgba(66, 0, 50, 1) inset;
}
.standardPlanDiv {
    transition: all .4s;
    position: absolute;
    bottom: -362px;
    left: 0px;
    background-color: white;
    width: calc(100% - 4px);
    height: 340px;
    padding-top: 20px;
    border: 2px dashed rgba(0, 0, 0, 0.1);
    border-top: none;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}
.enterprisePlanHeader {
    position: absolute;
    bottom: 0px;
    right: 120px;
    background-color: white;
    width: 260px;
    max-width: 25%;
    height: 150px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.enterprisePlanDiv {
    transition: all .4s;
    position: absolute;
    bottom: -400px;
    left: 0px;
    background-color: #f0f0f0;
    width: 100%;
    height: 400px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}
.planName {
    margin: 32px;
    margin-top: 38px;
    font-size: 18px;
    display: block;
    font-weight: 500;
}
.planPrice {
    margin: 32px;
    margin-top: -18px;
    font-size: 32px;
    display: block;
    font-weight: 800;
    // opacity: 0.7;
    b {
        font-size: 14px;
        font-weight: 400;
        margin-left: 6px;
        color: rgba(0, 0, 0, 0.6);
    }
}
.standardPlanName {
    margin: 32px;
    margin-top: 38px;
    font-size: 18px;
    display: block;
    font-weight: 500;
    color: white;
}
.standardPlanPrice {
    margin: 32px;
    margin-top: -18px;
    font-size: 32px;
    display: block;
    font-weight: 800;
    // opacity: 0.7;
    color: white;
    b {
        font-size: 14px;
        font-weight: 400;
        margin-left: 6px;
        color: rgba(255, 255, 255, 0.6);
    }
}
.pricingButton {
    transition: 0.3s;
    display: block;
    margin: auto;
    font-weight: 400;
    font-size: 14px;
    padding: 12px 24px;
    background-color: $dark_color;
    cursor: pointer;
    color: white;
    border: none;
    border-radius: 60px;
    position: relative;
    overflow: hidden;
    text-align: center;
    &:hover {
        opacity: 0.9;
        span {
            opacity: 0.1;
        }
    }
    span {
        position: absolute;
        height: 60px;
        width: 60px;
        border-radius: 60px;
        background-color: white;
        top: 1px;
        left: 20px;
        display: block;
        opacity: 0;
        transition: opacity 0.3s;
    }
    svg {
        height: 22px;
        vertical-align: middle;
        stroke: white;
        margin-left: 6px;
        margin-right: -14px;
    }
}
.selectorPlanItem {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    text-align: left;
    display: block;
    margin-left: 36px;
    margin-top: 12px;
    font-weight: 300;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    width: calc(100% - 98px);
    padding: 6px 12px;
    cursor: pointer;
    background-color: rgba(255,255,255,0.6);
}
.planItem {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    text-align: left;
    display: block;
    margin-left: 36px;
    margin-top: 28px;
    font-weight: 300;
}
.promotionSection {
    width: 90%;
    max-width: 1120px;
    margin: auto;
    padding: 120px 0px;
    padding-bottom: 250px;
    background-color: #1a0641;
    margin-top: 40vh;
    border-radius: 32px;
    margin-bottom: 20vh;
    position: relative;
    margin-bottom: 600px;
    box-shadow: -1px -9px 39px -2px rgba(70, 0, 60, 0.36);
    -webkit-box-shadow: -1px -9px 39px -2px rgba(70, 0, 60, 0.36);
    -moz-box-shadow: -1px -9px 39px -2px rgba(70, 0, 60, 0.36);
    a {
        // background: rgba(255,255,255,0.1);
        padding: 10px 20px;
        padding-right: 24px;
        padding-top: 10px;
        font-weight: 500;
        margin: auto;
        margin-top: 60px;
        display: block;
        border-radius: 50px;
        width: 120px;
        text-decoration: none;
        &:hover {
            opacity: 0.75;
        }
    }
    p {
        position: absolute;
        left: 22px;
    }
}
.promotionInput {
    width: 500px;
    max-width: 80%;
    border: 1px solid lightgray;
    margin: auto;
    padding: 10px;
    height: fit-content;
    margin-top: 90px;
    height: 50px;
    border-radius: 8px;
    position: relative;
}
footer {
    height: 160px;
    width: 100%;
    background: #fff;
    // border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.footerContainer {
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
    max-width: 1120px;
    margin: auto;
}
.footerColumn {
    padding-top: 70px;
    display: inline-block;
    width: 30%;
    a {
        color: #192439;
        text-decoration: none;
        margin-left: -40px;
        &:hover {
            font-weight: 500;
        }
    }
}
.footerRightColumn {
    padding-top: 60px;
    width: 30%;
    float: right;
}
.footerRightColumn img {
    float: right;
    height: 40px !important;
    vertical-align: middle;
    margin-left: 15px;
    border-radius: 4px;
    opacity: 1;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
        opacity: 0.5;
    }
}
.playStore {
    opacity: 1 !important;
    &:hover {
        opacity: 0.5 !important;
    }
}
@media (max-width: 1000px) {
    #phoneContainer {
        width: 100% !important;
        padding: 0px;
        background-color: rgba(0, 0, 0, 0.04);
    }
    #refundsContainer {
        width: 100% !important;
        padding: 0px;
    }
    .heroTitle {
        font-size: 36px;
        letter-spacing: -2px;
        width: 90%;
        margin: auto;
        margin-top: 60px;
    }
    #phoneController {
        position: absolute;
        height: 320px;
        width: 320px;
        border-radius: 240px;
        top: 40px;
        left: calc(50% - 160px);
        background-color: rgba(50, 50, 50, 0.04);
        z-index: 0;
        border: 2px solid rgba(0, 0, 0, 0);
        transition: 0.6s;
        background: transparent;
    }
    #phone {
        width: 244px;
        height: 503px;
        border-radius: 36px;
        box-shadow: 0 50px 100px -20px rgba(40, 40, 100, 0.4), inset 0 -2px 6px 0 rgba(0, 0, 0, 0.4);
        background-color: #f6f9fc;
        padding: 0px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 2px;
        z-index: 99;
        transition: 0.2s;
        position: absolute;
        top: -200px;
        left: calc(50% - 122px);
        pointer-events: none;
        transform: scale(0.7) !important;
    }
    .detailsSectionRightContainer {
        display: inline-block;
        width: 100%;
        margin-left: 0px;
        margin-top: 180px;
        .subTitle {
            margin: auto !important;
            margin-top: 120px !important;
            font-size: 32px;
            width: 90%;
            text-align: center;
        }
        .subHeadline {
            margin: auto !important;
            margin-top: 20px !important;
            width: 90%;
            text-align: center;
        }
    }
    .detailsSectionLeftContainer {
        display: inline-block;
        width: 100%;
        margin-left: 0px;
        margin-top: 180px;
        .subTitle {
            margin: auto !important;
            margin-top: 120px !important;
            font-size: 32px;
            width: 90%;
            text-align: center;
        }
        .subHeadline {
            margin: auto !important;
            margin-top: 20px !important;
            width: 90%;
            text-align: center;
        }
    }
    .detailsIllustration {
        width: 320px;
        height: 320px;
        left: calc(50% - 160px);
        background: transparent;
        .refundsImage {
            max-width: 120%;
            height: auto;
            margin-left: -8px;
        }
    }
    .detailsSection u span {
        top: 34px;
    }
}
@media (max-width: 1100px) {
    .bigHeadline {
        font-size: 34px;
        text-align: center;
        margin: auto;
        margin-bottom: 50px;
        margin-top: -50px;
    }
    .standardPlanHeader {
        position: relative;
        display: block;
        margin: auto;
        min-width: 260px;
        margin-bottom: 400px;
        left: 0px;
        padding-top: 1px;
    }
    .freePlanHeader {
        position: relative;
        display: block;
        margin: auto;
        min-width: 260px;
        margin-bottom: 400px;
        left: 0px;
        padding-top: 1px;
    }
    .enterprisePlanHeader {
        position: relative;
        display: block;
        margin: auto;
        min-width: 260px;
        left: 0px;
        padding-top: 1px;
    }
    .promotionSection {
        margin-bottom: 200px;
    }
}
.chevron::before {
	border-style: solid;
	border-width: 0.15em 0.15em 0 0;
	content: '';
	display: inline-block;
	height: 0.45em;
	right: 0.15em;
	position: relative;
	top: 0.65em;
	transform: rotate(-45deg);
	vertical-align: top;
	width: 0.45em;
    margin-left: 70px;
    margin-top: 2px;
    opacity: 0.4;
    pointer-events: none;
}

.chevron.right:before {
	left: 0;
	transform: rotate(45deg);
}

.chevron.bottom:before {
	top: 0;
	transform: rotate(135deg);
}
.chevron.left:before {
	left: 0.25em;
	transform: rotate(-135deg);
}
.planOptions {
    position: absolute;
    top: 65px;
    left: 36px;
    width: calc(100% - 92px);
    height: 90px;
    background-color: white;
    border-radius: 6px;
    padding: 10px;
}
.planOption {
    width: calc(100% - 26px);
    margin: 3px;
    padding: 10px;
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
}